<template>
    <v-container fluid class="background mb-4" v-if="booklists.length">
        <v-row class="fill-height">
            <v-col cols="5">
                <v-card class="booklist-active" variant="text" link @click="toggleBooks(activeBooklist.id)">
                    <v-img :src="activeBooklist.illustration" cover rounded="lg" class="image-effet"></v-img>
                </v-card>
            </v-col>
            <v-col cols="7" class="d-flex align-end">
                <v-row>
                    <v-col v-for="booklist in otherBooklists" :key="booklist.id" cols="6">
                        <v-card class="booklist-other" variant="text" link @click="toggleBooks(booklist.id)">
                            <v-img :src="booklist.illustration" cover rounded="lg" class="image-effet"></v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="py-0">
                <v-btn size="x-small" variant="flat" color="white" @click="toggleBooks(activeBooklist.id)">
                    <template v-slot:prepend>
                        <v-icon v-for="i in 3" :key="i" size="x-small" color="pc-bleu">
                            mdi-circle
                        </v-icon>
                    </template>

                    <strong :class="`text-${showBooks ? 'pc-brique' : 'black'}`">{{ showBooks ? 'Masquer les livres' :
                        'Afficher les livres' }}</strong> 
                        
                    <template v-slot:append>
                        <v-icon>{{ showBooks ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </template>
                </v-btn>
            </v-col>
            <v-col cols="6" class="py-0">
                <h2 class="text-pc-brique text-right">Titre du groupe</h2>
            </v-col>
        </v-row>

        <v-expand-transition>
            <div v-if="showBooks && activeBooklist.books.length > 0">
                <v-row class="d-flex justify-start">
                    <v-col v-for="book in activeBooklist.books" :key="book.id" cols="auto" class="pa-3">
                        <v-card variant="flat" color="white" elevation="16" rounded="0" link>
                            <v-img :src="book.coverUrl" :width="book.isWide ? '120px' : '90px'"
                                :aspect-ratio="book.isWide ? 16 / 9 : 2 / 3" cover></v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-expand-transition>
    </v-container>
</template>


<script setup>
import { ref, computed, onMounted } from 'vue';

const booklists = ref([]);
const activeBooklistId = ref(null);
const showBooks = ref(false);

const activeBooklist = computed(() =>
    booklists.value.find(bl => bl.id === activeBooklistId.value) || booklists.value[0]
);

const otherBooklists = computed(() =>
    booklists.value.filter(bl => bl.id !== activeBooklistId.value)
);

const toggleBooks = (id) => {
    if (activeBooklistId.value !== id) {
        activeBooklistId.value = id;
        showBooks.value = false;
    } else {
        showBooks.value = !showBooks.value;
    }
};



const navigateToBook = (bookId) => {
    console.log(`Naviguer vers le livre ${bookId}`);
};

const fetchBooklists = async () => {
    // Simulation d'un appel AJAX
    await new Promise(resolve => setTimeout(resolve, 500));

    booklists.value = [
        {
            id: 1,
            title: 'Booklist Principale',
            illustration: 'https://www.moveandread.com/moveandread/multimedia/site/1725729996.jpeg?t=1727947489',
            books: [
                { id: 1, title: 'Livre 1', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725467036/c/1725467036.png', isWide: false },
                { id: 2, title: 'Livre 2', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1724935412/c/1724935412.png', isWide: false },
                // ... autres livres
            ]
        },
        {
            id: 2,
            title: 'Booklist Secondaire 1',
            illustration: 'https://www.moveandread.com/moveandread/multimedia/site/1725729963.jpeg?t=1727947489',
            books: [
                { id: 3, title: 'Livre 3', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725723642/c/1725723642.png', isWide: false },
                { id: 4, title: 'Livre 4', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725723563/c/1725723563.png', isWide: false },
                // ... autres livres
            ]
        },
        {
            id: 3,
            title: 'Booklist Principale',
            illustration: 'https://www.moveandread.com/moveandread/multimedia/site/1725729982.jpeg?t=1727977416',
            books: [
                { id: 1, title: 'Livre 1', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1495553093/c/1495553093.png', isWide: false },
                { id: 2, title: 'Livre 2', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1727283835/c/1727283835.png', isWide: false },
                // ... autres livres
            ]
        },
        // ... autres booklists
    ];

    activeBooklistId.value = booklists.value[0].id;
};

onMounted(fetchBooklists);
</script>

<style scoped lang="scss">
[v-cloak] .v-cloak--hidden {
    display: none !important;
}

.background {
    position: relative;
    background-image: url('https://www.premierchapitre.fr/img/background-site-gris.jpg');
    background-size: cover;
    background-position: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); // Changez la couleur et l'opacité selon vos besoins
        z-index: 1;
    }

    >* {
        position: relative;
        z-index: 2;
    }
}

.booklist-active,
.booklist-other {
    transition: opacity .4s ease-in-out;
}

.booklist-other:not(.on-hover) {
    opacity: 1;
}


.image-effet {
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }
}
</style>