// @ts-ignore
console.log("page d'accueil");

import { createApp } from 'vue';
import vuetify from '@/env/shared/js/vuetify';

// Import du formulaire
import View from '@/env/libre/module/accueil/vue/indexView.vue';

// Récupérer l'élément et vérifier s'il est présent
const appView = document.querySelector('#app-view');

if (appView) {

    const app = createApp(View);

    app.use(vuetify);

    app.mount('#app-view')
} else {
    console.error('Élément #app-view non trouvé');
}
