<template>
    <v-container fluid class="background mb-4" v-if="booklist">
        <v-row class="d-flex justify-start align-end">
            <v-col v-for="book in displayedBooks" :key="book.id" cols="auto" class="pa-3">
                <v-card variant="flat" color="white" elevation="16" rounded="0" link>
                    <v-img :src="book.coverUrl" :width="book.isWide ? '120px' : '90px'"
                        :aspect-ratio="book.isWide ? 16 / 9 : 2 / 3" cover>
                    </v-img>
                </v-card>
            </v-col>
            <v-col cols="auto">
                <v-btn size="x-small" variant="flat" color="white" @click="toggleBookDisplay()">

                    <template v-slot:prepend>
                        <v-icon v-for="i in 3" :key="i" size="x-small" color="pc-bleu">
                            mdi-circle
                        </v-icon>
                    </template>

                    <strong :class="`text-${showAllBooks ? 'pc-brique' : 'black'}`">{{ showAllBooks ? 'Moins de livres'
                        : 'Plus de livres' }}</strong>
                    <template v-slot:append>
                        <v-icon>{{ showAllBooks ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </template>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-0">
                <h2 class="text-pc-brique text-right">{{ booklist.title }}</h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
    booklistId: {
        type: Number,
        required: true
    }
});

const booklist = ref(null);
const showAllBooks = ref(false);
const initialDisplayCount = ref(2);

const displayedBooks = computed(() => {
    if (!booklist.value) return [];
    return showAllBooks.value ? booklist.value.books : booklist.value.books.slice(0, initialDisplayCount.value);
});

const toggleBookDisplay = () => {
    showAllBooks.value = !showAllBooks.value;
};


const fetchBooklistData = async () => {
    // Simulation d'un appel AJAX
    // À remplacer par un vrai appel API lorsque ce sera prêt
    await new Promise(resolve => setTimeout(resolve, 500)); // Simule un délai réseau

    booklist.value = {
        id: props.booklistId,
        title: 'Chroniques dans le soir',
        backgroundImage: 'https://www.premierchapitre.fr/img/background-site-gris.jpg',
        books: [
            { id: 1, title: 'Les nuits d\'été', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1723048035/c/1723048035.png?t=1727947281', isWide: false },
            { id: 2, title: 'Le paradis ce matin', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1579001937/c/1579001937.png?t=1727947281', isWide: false },
            { id: 3, title: 'La cuillère', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1720467559/c/1720467559.png?t=1727947281', isWide: false },
            { id: 4, title: 'Mauvaises herbes', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1726134661/c/1726134661.png?t=1727947281', isWide: false },
            { id: 5, title: 'La petite dernière', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1703626854/c/1703626854.png?t=1727947282', isWide: false },
            { id: 6, title: 'Les nuits d\'été', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1723048035/c/1723048035.png?t=1727947281', isWide: false },
            { id: 7, title: 'Le paradis ce matin', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1579001937/c/1579001937.png?t=1727947281', isWide: false },
            { id: 8, title: 'La cuillère', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1720467559/c/1720467559.png?t=1727947281', isWide: false },
            { id: 9, title: 'Mauvaises herbes', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1726134661/c/1726134661.png?t=1727947281', isWide: false },
            { id: 10, title: 'La petite dernière', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1703626854/c/1703626854.png?t=1727947282', isWide: false },
            // Ajoutez d'autres livres ici...
        ]
    };
};

onMounted(fetchBooklistData);
</script>

<style scoped lang="scss">
.background {
    position: relative;
    background-image: url('https://www.premierchapitre.fr/img/background-site-gris.jpg');
    background-size: cover;
    background-position: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); // Changez la couleur et l'opacité selon vos besoins
        z-index: 1;
    }

    >* {
        position: relative;
        z-index: 2;
    }
}
</style>