<template>
    <Layout :activeMenu="activeMenu">
        <v-container fluid class="pe-0" style="padding-left:68px">
            <BooklistBlock :booklistId="3" />
            <MultipleBooklistsBlock />
            <BooklistBlock :booklistId="3" />
            <MultipleBooklistsBlock />
            <BooklistBlock :booklistId="3" />
            <MultipleBooklistsBlock />
        </v-container>
    </Layout>
</template>

<script setup>
import { ref, computed } from 'vue';
import Layout from '@/env/libre/vue/Layout.vue';
import BooklistBlock from '@/env/libre/vue/BooklistBlock.vue';
import MultipleBooklistsBlock from '@/env/libre/vue/MultipleBooklistsBlock.vue';

const activeMenu = ref('accueil');

const booklist = {
    id: 3,
    title: 'Chroniques dans le soir',
    backgroundImage: 'https://www.premierchapitre.fr/img/background-site-gris.jpg',
    books: [
        { id: 1, title: 'Les nuits d\'été', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1723048035/c/1723048035.png?t=1727947281', isWide: false },
        { id: 2, title: 'Le paradis ce matin', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1579001937/c/1579001937.png?t=1727947281', isWide: false },
        { id: 3, title: 'La cuillère', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1720467559/c/1720467559.png?t=1727947281', isWide: false },
        { id: 4, title: 'Mauvaises herbes', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1726134661/c/1726134661.png?t=1727947281', isWide: false },
        { id: 5, title: 'La petite dernière', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1703626854/c/1703626854.png?t=1727947282', isWide: false },
        // Ajoutez d'autres livres ici...
    ]
};
const booklists = ref([]);
booklists.value = [
    {
        id: 1,
        title: 'Booklist Principale',
        illustration: 'https://www.moveandread.com/moveandread/multimedia/site/1725729996.jpeg?t=1727947489',
        books: [
            { id: 1, title: 'Livre 1', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725467036/c/1725467036.png', isWide: false },
            { id: 2, title: 'Livre 2', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1724935412/c/1724935412.png', isWide: false },
            // ... autres livres
        ]
    },
    {
        id: 2,
        title: 'Booklist Secondaire 1',
        illustration: 'https://www.moveandread.com/moveandread/multimedia/site/1725729963.jpeg?t=1727947489',
        books: [
            { id: 3, title: 'Livre 3', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725723642/c/1725723642.png', isWide: false },
            { id: 4, title: 'Livre 4', coverUrl: 'https://www.moveandread.com/moveandread/multimedia/ebook/1725723563/c/1725723563.png', isWide: false },
            // ... autres livres
        ]
    },
    // ... autres booklists
];

</script>