<template>
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
        <template v-slot:activator="{ props }">
            <v-text-field v-model="searchInput" :placeholder="placeholder" prepend-inner-icon="mdi-magnify"
                variant="outlined" density="comfortable" hide-details single-line clearable
                @update:model-value="onSearchInput" @click:clear="clearSearch" v-bind="props"></v-text-field>
        </template>

        <v-card min-width="300" max-height="600" class="overflow-y-auto">
            <!-- Pagination en haut -->
            <v-card-text v-if="serverItemsLength > itemsPerPage" class="d-flex justify-space-between align-center py-2">
                <span>{{ serverItemsLength }} résultats</span>
                <v-pagination v-model="page" :length="Math.ceil(serverItemsLength / itemsPerPage)" :total-visible="5"
                    density="compact" @update:model-value="onPageChange"></v-pagination>
            </v-card-text>

            <v-list v-if="serverItems.length > 0">
                <v-list-item v-for="(item, index) in serverItems" :key="index" :href="getItemUrl(item)" class="border-b"
                    lines="tow">
                    <template v-slot:prepend>
                        <v-img :src="item.couverture" :alt="item.titre" width="40" height="60" cover
                            class="mr-3"></v-img>
                    </template>
                    <v-list-item-title>{{ item.titre }}</v-list-item-title>
                    <v-list-item-subtitle>
                        <template v-if="item.auteurs && Array.isArray(item.auteurs) && item.auteurs.length > 0">
                            {{ formatAuthors(item.auteurs) }}
                        </template>
                        <template v-else>
                            Auteur(s) inconnu(s)
                        </template>
                        -
                        {{ item.editeurNom || 'Éditeur inconnu' }}
                        -
                        <span>{{ item.dateParution }}</span>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
            <v-card-text v-else-if="searchInput && !loading">
                Aucun résultat trouvé.
            </v-card-text>
            <v-card-text v-if="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                Recherche en cours...
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script setup>
import { ref, watch } from 'vue';

import axios from 'axios';
import { debounce } from 'lodash';

const props = defineProps({
    placeholder: {
        type: String,
        default: "Rechercher par : titre / auteur / éditeur"
    },
    apiUrl: {
        type: String,
        default: "/api/ebook/liste"
    },

});

const menu = ref(false);

const serverItems = ref([]);
const serverItemsLength = ref(0);
const loading = ref(false);
const search = ref('');
const searchInput = ref('');
const itemsPerPage = ref(7);
const sortBy = ref([{ date_parution: 'desc' }, { titre: 'asc' }]);
const page = ref(1);

const formatAuthors = (authors) => {
    if (!authors || !Array.isArray(authors) || authors.length === 0) return 'Auteur(s) inconnu(s)';
    return authors.map(author => `${author.auteur_prenom || ''} ${author.auteur_nom || ''}`).join(', ').trim();
};

const debouncedSearch = debounce((value) => {
    search.value = value;
    page.value = 1;
    fetchData();
}, 500);

const clearSearch = () => {
    searchInput.value = '';
    serverItems.value = [];
    serverItemsLength.value = 0;
    page.value = 1;
    menu.value = false;
};

const onPageChange = () => {
    fetchData();
};

const onSearchInput = (value) => {
    searchInput.value = value;
    debouncedSearch(value);
};

const normalizeSearch = (search) => {
    return search
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/`|"|'|'|\?|´/g, "'")  // Le '?' est maintenant échappé
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, " ");
};

const fetchData = async () => {
    if (loading.value) return;
    if (!search.value || searchInput.value.trim() === '') {
        clearSearch();
        return;
    }
    loading.value = true;

    try {
        const params = {
            page: page.value,
            itemsPerPage: itemsPerPage.value,
            sort: JSON.stringify(sortBy.value),
            search: normalizeSearch(search.value),
        };
        console.log('Fetching with params:', params);
        const { data } = await axios.get(props.apiUrl, { params });
        serverItems.value = data.items;
        serverItemsLength.value = data.total;
        console.log('Received data:', { total: data.total, items: data.items, search: search.value });
        if (serverItems.value.length > 0) {
            menu.value = true;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        serverItems.value = [];
        serverItemsLength.value = 0;
    } finally {
        loading.value = false;
    }
};

const getItemUrl = (item) => {
    if (item && item.titreSlug && item.uuid) {
        return `/ebook/${item.titreSlug}/${item.clefSecurite}`;
    }
    console.warn('Invalid item data:', item);
    return '#';
};

// Surveiller les changements de page
watch(page, () => {
    if (searchInput.value.trim() !== '') {
        fetchData();
    }
});
</script>

<style scoped>
.v-list-item__prepend {
    align-self: flex-start;
    margin-top: 8px;
}
</style>