<template>
    <v-app>
        <!-- En-tête Desktop -->
        <template v-if="!isMobile">
            <v-app-bar app flat absolute density="prominent" color="white" class="pa-0">
                <v-row no-gutters align="center" class="w-100" style="height: 100%;">

                    <!-- Logo à gauche -->
                    <v-col cols="3" class="d-flex justify-start align-center" style="height: 100%;">
                        <v-img :src="logoPremierChapitre" alt="Logo Premier Chapitre" class="logo-small"></v-img>
                    </v-col>

                    <!-- Slogan au centre avec le bouton de recherche en colonne -->
                    <v-col cols="6" class="d-flex align-center justify-center" style="height: 100%;">
                        <div class="mx-auto text-center" style="padding-top: 10px; width: 84%;">
                            <div class="mb-3">
                                <span class="font-weight-medium"><em>{{ slogan }}</em></span>
                            </div>
                            <SearchComponent />
                        </div>
                    </v-col>

                    <!-- Version du mois à droite -->
                    <v-col cols="3" class="d-flex align-center justify-end" style="height: 100%;">
                        <span>{{ versionMois }}</span>
                    </v-col>

                </v-row>
            </v-app-bar>
        </template>

        <!-- En-tête Mobile -->
        <template v-else>
            <v-app-bar app flat absolute color="white">
                <v-container class="d-flex justify-center">
                    <v-img :src="logoPremierChapitre" alt="Logo Premier Chapitre" max-height="40"></v-img>
                </v-container>
            </v-app-bar>
        </template>

        <v-app-bar app :elevation="0" density="compact" absolute>
            <!-- Mode Mobile avec Menu Hamburger -->
            <template v-if="isMobile">
                <v-toolbar color="pc-brique">
                    <v-btn @click="drawer = !drawer" class="item-menu">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- Accès environnement client -->
                    <v-btn href="/client/auth/login" class="item-menu">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>

            <!-- Mode Desktop avec Barre de Navigation Complète -->
            <template v-else>
                <v-toolbar color="pc-brique">
                    <v-btn class="item-menu font-weight-medium" height="100%" variant="flat" :href="item.url"
                        :class="{ 'active': activeMenu === item.menu }" v-for="(item, index) in menuItems" :key="index">
                        <!-- Affiche uniquement l'icône si elle existe -->
                        <v-icon v-if="item.icon" left>{{ item.icon }}</v-icon>

                        <!-- Affiche le titre seulement s'il n'y a pas d'icône -->
                        <span v-if="!item.icon">
                            {{ item.title }}
                        </span>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- Accès environnement client -->
                    <v-btn href="/client" class="item-menu">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
        </v-app-bar>

        <!-- Menu Drawer -->
        <v-navigation-drawer v-model="drawer" temporary id="nav-mobile">
            <v-list>
                <v-list-item v-for="(item, index) in menuItems" :key="index">
                    <v-list-item-title>
                        <a :href="item.url" :class="{ 'active': activeMenu === item.menu }"
                            class="text-decoration-none d-flex align-center rounded item">
                            <span>
                                {{ item.title }}
                            </span>
                        </a>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <!-- Contenu Principal -->
        <v-main>
            <slot :isMobile="isMobile"></slot> <!-- Ici, le contenu spécifique à chaque vue sera injecté -->
        </v-main>

        <!-- Pied de Page avec Indicateur de Mode et Taille d'Écran -->
        <v-footer app absolute color="#a6c1ec" dark>
            <v-row no-gutters class="w-100">
                <v-col class="text-start">
                    <span>&copy; 2024 - Tous droits réservés</span>
                </v-col>
                <v-col class="text-end">
                    <small class="text-blue-grey-lighten-2">
                        <v-icon v-if="isMobile">mdi-cellphone-screenshot</v-icon>
                        <v-icon v-else>mdi-monitor-screenshot</v-icon>
                        {{ screenSize }}
                    </small>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>


<script setup>
// 
import { ref, watchEffect } from 'vue';
//
import { useDisplay } from 'vuetify';
// 
import SearchComponent from '@/env/libre/vue/SearchComponent.vue';

const props = defineProps({
    activeMenu: String,
    content: String,
    slogan: String,
    versionMois: String
});

const activeMenu = ref(props.activeMenu || '/');

const logoPremierChapitre = ref('/img/logo.png');
const slogan = ref(props.slogan || 'Romans, BD, livres jeunesse, essais ou livres audios pour vos lecteurs');
const versionMois = ref(props.versionMois || '#101 avril 2024');
const drawer = ref(false);

const menuItems = [
    { title: 'Accueil', url: '/', icon: 'mdi-home', menu: 'accueil' },
    { title: 'Booklists', url: '/booklist', menu: 'booklist' },
    { title: 'Éditeurs', url: '/editeur', menu: 'editeur' },
    { title: 'Qui sommes-nous ?', url: '/qui-sommes-nous', menu: 'qui' },
    { title: 'On parle de premierchapitre', url: '/on-parle-de-premierchapitre', menu: 'presse' },
    { title: 'Contact', url: '/contact', menu: 'contact' },
];


// Utilisation de useDisplay pour détecter le mode d'affichage
const { xs, sm, md, lg, xl } = useDisplay();
const isMobile = ref(xs.value);
const screenSize = ref('');

// Watcher pour surveiller les changements de mode d'affichage
watchEffect(() => {
    if (xs.value) {
        screenSize.value = 'xs';
        isMobile.value = true;
    } else if (sm.value) {
        screenSize.value = 'sm';
        isMobile.value = true;
    } else if (md.value) {
        screenSize.value = 'md';
        isMobile.value = false;
    } else if (lg.value) {
        screenSize.value = 'lg';
        isMobile.value = false;
    } else if (xl.value) {
        screenSize.value = 'xl';
        isMobile.value = false;
    }
});
</script>

<style scoped lang="scss">
[v-cloak] .v-cloak--hidden {
    display: none !important;
}

.btn-search {
    text-transform: none;
}

.item-menu {
    background: rgb(var(--v-theme-pc-brique));
    color: white;
    text-transform: none;
    font-size: 1.1rem;

    &:hover,
    &:focus {
        transition: 0.3s ease-out;
        background: rgb(var(--v-theme-pc-bleu));
        color: black;
    }

    &.active {
        background: #a6c1ec;
        color: black;
    }
}

#nav-mobile {
    .item {
        color: black;
        padding: 0.2rem 0.5rem;

        &:hover,
        &:focus {
            transition: 0.3s ease-out;
            background: #a6c1ec;
            color: black;
        }

        &.active {
            background: #a6c1ec;
            color: black;
        }
    }
}
</style>